<template>
  <section>
    <v-row>
      <v-col cols="12" xl="8" lg="10">
        <v-btn color="success" outlined @click="dialog=true">New Embed</v-btn>
        <v-simple-table fixed-header class="my-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left caption">Topic</th>
                <th class="text-left caption">Link</th>
                <th class="text-left caption">Logs</th>
                <th class="text-left caption">Status</th>
                <th class="text-left caption">Status</th>
                <th class="text-left caption">Created</th>

                <th class="text-left caption"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in embeds" :key="item.id">
                
                <td class="body-2">{{ item.topic}}</td>
                <td class="body-2" width="20%">
                  <v-list-item-title>
                    {{ item.link.slice(0,50) }}...
                  </v-list-item-title>
                </td>
                <td class="body-2">{{ item.logs_count}}</td>
                 <td class="caption">
                  <v-chip
                    class="ma-1 caption"
                    v-for="(member, i) in item.members"
                    :key="i"
                  >
                    {{ $helpers.level(member.member_level) }}
                  </v-chip>
                </td>
                <td :class="
                  item.status == 0 ? '' : item.status == 1 ? 'success--text' : 'red--text'
                ">
                  {{item.status == 0 ? 'Not Started' : item.status == 1 ? 'Live' : 'Endend'}}
                </td>
                <td class="caption">{{ $helpers.dateDiff(item.created_at) }}</td>
                <td class="d-flex align-center">
                  <v-btn icon small v-if="item.status == 0 || item.status == 2">
                    <v-icon small @click="start(item.id)">mdi-play</v-icon>
                  </v-btn>
                  <v-btn v-else icon small @click="stop(item.id)" color="red">
                    <v-icon>mdi-stop</v-icon>
                  </v-btn>
                  <v-btn small icon @click="remove(item.id)">
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog max-width="350" v-model="dialog">
      <v-card>
         <v-card-title
        class="d-flex justify-space-between align-center success white--text"
      >
        <div class="">New Embed</div>
        <v-btn icon small @click="dialog=false">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="mt-5" @submit.prevent="create" ref="createForm">
          <label class="caption">TOPIC</label>
          <v-text-field solo v-model="form.topic"/>
          <label class="caption">VIDEO LINK</label>
          <v-text-field solo v-model="form.link"/>
          <label class="caption">TYPE OF STUDENT</label>
        <v-select
          :items="$helpers.userLevel()"
          v-model="form.user_level"
          multiple
          solo
          chips
          clearable
          small-chips
        />
        <v-btn block color="success" type="submit">Create</v-btn>
        </v-form>
      </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar :snack="snack" :text="snackText" @close="snack = !snack" timeout="2000"/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data: () => ({
    dialog: false,
    form: {
      link: '',
      topic: '',
      user_level: []
    },
    snack:false,
    snackText: '',
  }),
  computed: {
    ...mapState('admin', {
      embeds: (state) => state.embeds
    })
  },
  mounted() {
    this.getEmbeds()
  },
  methods: {
    ...mapActions('admin', [
      'storeEmbed',
      'getEmbeds',
      'startEmbed',
      'stopEmbed',
      'deleteEmbed',
    ]),

    create() {
      console.log(this.form);
      this.storeEmbed(this.form)
      .then(() => {
        if(this.$errors('admin')) return
        this.snack = true
        this.snackText = 'Video Embed Saved!'
        this.dialog = false
        this.$refs.createForm.reset()
      })
    },

    start(id) {
      console.log(id);
      if(confirm('Start this meeting?')) {
        this.startEmbed(id)
      }
    },

    stop(id) {
      console.log(id);
      if(confirm('Stop this meeting?')) {
        this.stopEmbed(id)
      }
    },

    remove(id) {
      console.log(id);
      if(confirm('Delete this meeting?')) {
        this.deleteEmbed(id)
      }
    }
  }
}
</script>
